@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.buttons {
  position: relative;
  height: 38px;
  margin-top: fn.spacing(3);
  z-index: 2;
  margin-bottom: fn.spacing(4.7);
}

.button-apple {
  height: 38px;
  width: 118px;
  margin-right: fn.spacing(2);
}

.button-google {
  width: 134px;
  height: 38px;
}

.grid-item-text {
  text-align: left;
}

.text-link {
  display: inline;
}

.svg-link {
  padding-top: fn.spacing(0.5);
}

.fullbg {
  background: var.$color-blue-50;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease;

  &.fullbg-selected {
    transition: opacity 1s ease;
  }
}

.fullbg-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .fullbg-content-link {
    margin-top: fn.spacing(6);
  }
}

.text-container {
  padding: fn.spacing(2) 0;
}

.outer-grid-container {
  min-height: fn.spacing(35);
}

.outer-grid-container-long {
  min-height: fn.spacing(65);
}

.font-black {
  color: var.$color-content-primary;
}

.font-white {
  color: var.$color-neutral-0;
}

.font-blue {
  color: var.$color-blue-500;
}

.eyebrow {
  text-align: left;
  padding-bottom: 0;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .text-padding-left {
    padding-left: fn.spacing(5);
  }

  .outer-grid-container {
    min-height: fn.spacing(45);
  }

  .fullbg-content {
    padding: 0;
  }

  .title {
    position: relative;
    margin-bottom: fn.spacing(2);
    z-index: 2;
  }

  .sub-title {
    position: relative;
    margin-bottom: fn.spacing(2);
    z-index: 2;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .fullbg-content {
    max-width: 1007px;
    width: 1007px;
    padding: fn.spacing(4) 0;
  }
}

/* Ultra (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xxl) {
  .fullbg-content {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}

// Only mobile
@media screen and (max-width: var.$breakpoint-md) {
  .fullbg-content.bottom {
    padding: fn.spacing(4) fn.spacing(4) fn.spacing(42);
  }

  .fullbg-content.top {
    padding: fn.spacing(42) fn.spacing(4) fn.spacing(4);
  }
}
