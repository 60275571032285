@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/backgrounds' as bg;

.grid-item-text {
  text-align: left;
  justify-content: center;
  flex-direction: column;
  max-width: 447px;
}

.phone-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: fn.spacing(2);
}

.phone-link {
  text-decoration-color: var.$color-neutral-0 !important; //Wont work otherwise
}

.phone-text {
  margin: 0 0 0 fn.spacing(1);
  color: var.$color-neutral-0;
}

.text-link {
  display: inline;
}

.svg-link {
  padding-top: fn.spacing(0.5);
}

.fullbg {
  background: var.$color-blue-50;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease;

  &.fullbg-selected {
    transition: opacity 1s ease;
  }
}

.fullbg-content {
  position: relative;
  display: flex;
  text-align: center;
  min-height: 65vh;

  .fullbg-content-link {
    margin-top: fn.spacing(6);
  }
}

.text-container {
  z-index: 1;
  position: relative;
  transform: translateY(50%);

  &[data-container-color='blue'] {
    background-color: var.$color-blue-500;
    padding: fn.spacing(3);
    border-radius: var.$achieve-default-border-radius;
  }

  > * + * {
    display: block;
    margin-top: fn.spacing(3);
  }
}

.font-black {
  color: var.$color-content-primary !important;
}

.font-white {
  color: var.$color-neutral-0 !important;
}

.font-blue {
  color: var.$color-blue-500 !important;
}

.eyebrow {
  padding-bottom: 0;
}

.link-cta {
  margin-top: fn.spacing(3);
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    padding: 0;
    margin: 0;
  }
}

.font-align-center {
  text-align: center !important;
}

.font-align-left {
  text-align: left !important;
}

.font-align-right {
  text-align: right !important;
}

.subnav-outer-container {
  position: relative;
  max-width: 100%;
}

.subnav-container {
  justify-content: center;
  position: sticky;
  z-index: 9;
  background-color: var.$color-neutral-0;
  transform: translateY(-1px);
  transition: top 0.05s linear 0.05s;

  > div {
    width: 100%;
    max-width: 100%;
    position: relative;
  }
}

.slider-tool-paper {
  box-shadow: none;
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.slider-tool-wrapper {
  width: 100%;
  margin: 0 0 fn.spacing(3) 0;
}

.slider-tool-value {
  color: var.$color-neutral-0;
  text-align: center;
}

.slider-tool-slider {
  width: 100%;

  [class^='MuiSlider-thumb'] {
    background-color: var.$color-blue-200;
    border: var.$color-blue-25 solid 2px;
    height: 20px;
  }

  [class^='MuiSlider-track'] {
    color: var.$color-blue-200;
    background-color: var.$color-blue-200;
    height: 10px;
    border: 0;
  }

  [class^='MuiSlider-rail'] {
    background-color: var.$color-blue-25;
    opacity: 1;
    height: 10px;
    border: 0;
  }
}

.marks {
  display: none;
}

.slider-tool-min-mark {
  // value didn't fit within standard spacing
  margin-left: 50px;
  color: var.$color-neutral-0;
}

.slider-tool-max-mark {
  margin-left: fn.spacing(-10);
  color: var.$color-neutral-0;
}

.slider-tool-cta {
  margin-top: 0;
  min-width: max-content;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .phone-container {
    justify-content: left;
  }

  .link-cta {
    margin-top: 0;
  }

  .title,
  .title-no-eyebrow {
    margin-bottom: fn.spacing(2);
  }

  .sub-title {
    margin-bottom: fn.spacing(2);
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .fullbg-content {
    min-height: 723px;
    padding: fn.spacing(10) 0;
  }

  .text-container {
    transform: none;

    &[data-container-color='blue'] {
      padding: fn.spacing(5);
    }
  }

  .mobile-spacer {
    display: none;
  }
}

/* Ultra (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xxl) {
  .fullbg-content {
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: initial;
  }
}

/* Home Hero variant ******************************************************************************/
[data-hero-variant='home'] {
  .grid-item-text {
    max-width: 447px;
  }

  & + .mobile-spacer {
    background-color: var.$color-blue-50;
  }

  @media screen and (min-width: var.$breakpoint-xl) {
    .grid-item-text {
      max-width: 506px;
    }
  }
}

/* Debt Consolidation Hero variant ****************************************************************/

@media screen and (max-width: var.$breakpoint-min-lg) {
  .section[data-hero-variant='debt-consolidation'] {
    height: 342px !important;
  }
}
@media screen and (max-width: var.$breakpoint-min-md) {
  .section[data-hero-variant='debt-consolidation'] {
    height: 345px !important;
  }
}

[data-hero-variant='debt-consolidation'] {
  .fullbg-content {
    min-height: 30vh;
  }

  .grid-item-text {
    max-width: 375px;
    padding-top: 246px;
  }

  .text-container {
    transform: inherit;

    &[data-container-color='blue'] {
      padding: fn.spacing(4);
      background: bg.$linear-bg-color-dc;
    }

    > * + * {
      margin-top: fn.spacing(2);
      display: block;
    }
  }

  .title {
    margin-top: fn.spacing(1);
  }

  .handwritten-text-container {
    display: none;
  }

  .handwritten-text {
    bottom: 0;
    height: 63px;
    text-align: center;
    width: 410px;
  }

  .cta-button {
    width: 100%;
    max-width: 280px;
  }

  & + .mobile-spacer {
    background: var.$color-neutral-0;
  }

  @media screen and (min-width: var.$breakpoint-md) {
    .grid-item-text {
      max-width: 462px;
    }

    .fullbg-content {
      min-height: 22vh;
    }

    .text-container {
      transform: inherit;
    }
  }

  @media screen and (min-width: var.$breakpoint-lg) {
    .fullbg-content {
      min-height: 363px;
      padding: 0;
    }

    .text-container {
      transform: none;

      &[data-container-color='blue'] {
        padding: fn.spacing(5);
      }
    }

    & + .mobile-spacer {
      display: none;
    }

    .slider-tool-wrapper {
      margin: 0 0 fn.spacing(3) 0;
    }
  }

  @media screen and (max-width: var.$breakpoint-min-md) {
    .grid-item-text {
      max-width: 340px;
      padding-top: 215px;
    }
  }

  @media screen and (min-width: var.$breakpoint-lg) {
    .grid-item-text {
      max-width: 340px;
      padding: fn.spacing(6) fn.spacing(0);
    }

    .sub-title {
      padding-bottom: fn.spacing(1);
    }

    .slider-tool-paper {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
    }
  }

  @media screen and (min-width: var.$breakpoint-xl) {
    .fullbg-content {
      padding: fn.spacing(0);
      min-height: inherit;
    }

    .title {
      margin-top: fn.spacing(1);
    }

    .text-container {
      &[data-container-color='blue'] {
        padding: fn.spacing(4) fn.spacing(5);
      }
    }

    .handwritten-text-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .grid-item-text {
      grid-gap: fn.spacing(6);
      max-width: 462px;
      padding: fn.spacing(6) fn.spacing(0);
    }
  }
}
